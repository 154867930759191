import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    locatie: "",
    rol: "",
    email: "",
    name: "",
  },
  reducers: {
    setLocatie: (state, action) => {
      state.locatie = action.payload;
    },
    setRole: (state, action) => {
      state.rol = action.payload;
    },
    setName: (state, action) => {
      state.name = action.payload;
    },
  },
});

export const { setLocatie, setRole, setName } = userSlice.actions;

export default userSlice.reducer;
